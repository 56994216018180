import React, { PureComponent } from "react";
import styled from "styled-components";
import dayjs from "dayjs";

import Layout from "./Layout";
import PostsFeed from "./PostsFeed";
import FeaturedHeader from "./FeaturedHeader";
import JoinDiscord from "../Header/JoinDiscord";
import { H2, P } from "../Typography";

const JoinDiscordParent = styled.div`
  width: 100%;
  padding: 6rem 2rem;
  text-align: center;

  .join-discord {
    display: inline-block;
  }
`;

const Intro = styled.section`
  width: 100%;
  max-width: 65rem;
  padding: 1rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1rem;
  margin-top: 1rem;

  h2 {
    margin-top: 1rem;
  }
`;

export const BlogContentStyling = styled.div`
  .speakerdeck-embed-wrapper { 
    max-width: 1000px; 
    margin: 0 auto;
  }

  iframe {
    width: 100%;
  }

  .accordion {
    background-color: #1c1430;
    color: #ddd;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;

    &::after {
      font-size: 13px;
      color: #ddd;
      float: right;
      margin-left: 5px;
      content: "◀";
      display: inline-block;
      transition: transform 0.2s;
    }
    
    &.active::after {
      content: "◀";
      transform: rotate(-90deg);
    }
    
    &.active,
    &:hover {
      background-color: #5b4792;
    }

  }

  /* Style the accordion panel. Note: hidden by default */
  .panel {
    padding: 0 18px;
    background-color: #261b42;
    display: none;
    overflow: hidden;
  }
 
  margin-bottom: 3rem;
  font-family: var(--font);
  color: #fff;
  text-rendering: optimizeLegibility;

  -webkit-font-smoothing: antialiased;

  .kg-image-card {
    text-align: center;
  }

  figure:not(.kg-embed-card), .ghost-video {
    max-width: 770px;
    margin: 1rem auto;
    position: relative;
  }
  
  .ghost-video {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
  }

  @media (max-width: 800px) {
    .ghost-video {
      max-width: 100%
    }
  }

  figure.kg-width-wide:not(.kg-embed-card) {
    max-width: 1000px;
    margin: 1rem auto;
    padding: 2rem;
  }

  figure.kg-card.kg-embed-card {
    padding-bottom: 0;
    margin: 40px auto;
    max-width: 740px;
    height: auto;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  figure.kg-card figcaption {
    top: 100%;
    width: 100%;
    text-align: center;
    padding: 20px 0;
    font-size: 18px !important;
    font-style: italic;
  }

  .kg-gallery-container {
    display: flex;
    flex-wrap: wrap;
  }
  .kg-gallery-row {
    width: 100%;
    display: flex;
  }
  .kg-gallery-image {
    padding: 1rem;
  }
  .kg-gallery-image img {
    height: auto;
    margin-bottom: 0;
  }

  figure.kg-width-full:not(.kg-embed-card) {
    max-width: 130rem;
  }

  img {
    width: auto;
    margin-bottom: 0.5rem;
    max-width: 100%;
  }

  .embed-parent {
    max-width: 740px;
    margin: 20px auto;
    padding: 20px;
  }
  figure.kg-embed-card {
    width: 100%;

    position: relative;
    padding-bottom: 56%;
    height: auto;

    iframe {
      height: 100% !important;
      width: 100% !important;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  h1 {
    font-size: 3.6rem;
  }
  h2 {
    font-size: 2.7rem;
  }
  h3 {
    font-size: 2.1rem;
  }
  h4 {
    font-size: 1.2rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: var(--condensedFont);
    padding: 0rem 20px;
    margin-bottom: 0.5rem;
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    font-weight: 600;
  }

  pre {
    background-color: #2c2641;
    padding: 3rem 2rem;
    border-radius: 8px;
    margin: 2rem auto;
  }

  a {
    color: var(--lilac);
  }
  a:hover {
    text-decoration: underline;
  }

  code {
    background-color: #2c2641;
    padding: 2px 5px;
    border-radius: 3px;
    display: inline;
    font-size: 1.1rem;
  }

  ul,
  pre,
  blockquote {
    max-width: 740px;
    padding: 0.7rem 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.7rem;
    line-height: 1.4;
  }
  ul {
    padding-left: 3rem;
  }

  blockquote {
    font-size: 1.2rem;
    line-height: 1.4;
    padding: 1rem 2rem;
    color: var(--lilac);
  }

  b,
  strong {
    color: var(--lilac);
  }

  h3 {
    line-height: 1.1;
    font-family: var(--condensedFont);
    font-size: 1.7rem;
  }
  pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }

  h1,
  h2,
  h3 {
    padding-top: 1.2rem;
  }

  p {
    max-width: 740px;
    line-height: 1.4;
    font-weight: lighter;
    margin-left: auto;
    margin-right: auto;
    padding: 0.7rem 20px;
    font-size: 21px;
  }
  li {
    font-size: 1.2rem;
  }

  hr {
    max-width: 43rem;
    margin: 2rem auto;
    border: 0;
    border-top: 1px solid;
    color: #403269;
  }

/* Style the buttons that are used to open and close the accordion panel */


  /* .ghost-video {
    width: 60%;
    margin: 0 auto;
    margin-top: 30px;
    display: block;
  }

  @media (max-width: 1100px) {
    .ghost-video {
      width: 80%;
    }
  } */
`;

// const FeatureImageParent = styled.div`
//   padding: 2rem 2rem;
//   margin-bottom: 1rem;

//   max-width: 84rem;
//   margin-left: auto;
//   margin-right: auto;

//   @media (max-width: 750px) {
//     padding: 0rem;
//     margin-bottom: 2rem;
//   }
// `

// const FeatureImage = styled.figure`
//   width: 100%;
//   height:0;
//   padding-bottom: 45%;
//   position: relative;
//   overflow: hidden;

//   img {
//     position: absolute;
//     top: 50%; left: 50%;
//     transform: translate(-50%, -50%);
//     width: 100%;
//   }

//   @media (max-width: 750px) {
//   }
// `

const Author = styled.div`
  margin-top: 1rem;
  p,
  a {
    display: inline;
  }
  a {
    padding-right: 0.75rem;
    color: var(--lilac);
  }
  a:hover {
    text-decoration: underline;
  }
  p:last-of-type {
    padding-left: 0.75rem;
  }
`;

export default class Post extends PureComponent {
  componentDidMount() {
    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        /* Toggle between adding and removing the "active" class,
        to highlight the button that controls the panel */
        this.classList.toggle("active");

        /* Toggle between hiding and showing the active panel */
        var panel = this.nextElementSibling;

        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  }

  render() {
    const { post, posts } = this.props;

    const formattedDate = dayjs(post.published_at).format("MMM DD, YYYY");
    const authorName = post.primary_author.name;

    let authorUrl = post.primary_author.website ? post.primary_author.website : false;

    let filteredPosts = posts.edges.filter((filteredPost, i) => {
      if (filteredPost.node.title === post.title) {
        return false
      }

      const tags = filteredPost.node.tags.map(({ name }) => name)

      if (tags.includes('hidden')) {
        return false
      }

      return true
    });

    if (filteredPosts.length > 3) {
      filteredPosts = filteredPosts.slice(0, 3)
    }

    // replace iframe with parent
    // const html = post.html.replace('<!--kg-card-begin: embed-->', '<div class="embed-parent">').replace('<!--kg-card-end: embed-->', '</div>')
    const html = post.html
      .replace(/<a\s+href=/gi, '<a target="_blank" href=')
      .replace(/<video/g, '<video style="" class="ghost-video" playsinline');

    return (
      <Layout>
        <Intro>
          <H2 color="#fff">{post.title}</H2>
          <Author>
            <a href={authorUrl} target="_blank" rel="noopener noreferrer">
              <P color="var(--lilac)">{authorName}</P>
            </a>
            <P color="var(--lilac)">—</P>
            <P color="var(--lilac)">{formattedDate}</P>
          </Author>
        </Intro>

        <BlogContentStyling>
          <article dangerouslySetInnerHTML={{ __html: html }} />
        </BlogContentStyling>

        <JoinDiscordParent>
          <JoinDiscord content="Join the discussion on discord" />
        </JoinDiscordParent>

        <FeaturedHeader>Recent Posts</FeaturedHeader>
        <PostsFeed posts={filteredPosts} />
      </Layout>
    );
  }
}
