import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import "../css/main.css";
import "../css/fonts.css";
import "../css/reset.css";

import PostComponent from "../components/Blog/Post";
import Seo from "../components/SeoHead";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */

const Post = ({ data, location }) => {
  const post = data.ghostPost;
  const posts = data.allGhostPost;


  return (
    <>
      <Seo img={post.feature_image} title={post.title} desc={post.excerpt} />

      <Helmet>
        <title>{post.title} - Skyweaver</title>
      </Helmet>

      <PostComponent posts={posts} post={post} />
    </>
  );
};

export default Post;

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      meta_title
      url
      slug
      html
      published_at
      tags {
        name
        slug
        url
      }
      title
      excerpt
      featured
      feature_image
      og_image
      primary_author {
        name
        website
      }
    }

    allGhostPost(sort: { order: DESC, fields: [published_at] }) {
      edges {
        node {
          url
          slug
          html
          published_at
          tags {
            name
            slug
            url
          }
          title
          excerpt
          featured
          feature_image
          og_image
        }
      }
    }
  }
`;
